<template>
  <div class="subscribe">
    <b-container fluid>
      <b-row align-v="center" align-h="between">
        <b-col sm="12" lg="6">
          <div class="text-center">
            <b-img
              fluid
              class="h-30"
              :src="
                require('@/assets/imgs/illustrations/subscribe/undraw-subscriber-vabu.svg')
              "
            />
          </div>
        </b-col>

        <b-col sm="12" lg="6" class="project__content">
          <article>
            <h2 class="main-heading main-heading--subscribe">{{ $t("heading.subscribe_to") }}</h2>
            <p class="main-description mb-5" v-html="subscribeDescription">
              <!-- {{ subscribeDescription }} -->
            </p>
          </article>

          <form-wrapper :validator="$v.form">
            <b-row class="form-box form-box--subscribe">
              <b-col md="6">
                <form-group attribute="input.email" name="email">
                  <b-form-input
                    slot-scope="{ attrs, listeners }"
                    v-model="form.email"
                    class="form-box__input"
                    v-bind="attrs"
                    type="email"
                    :placeholder="$t('input.email')"
                    trim
                    v-on="listeners"
                  />
                </form-group>
              </b-col>
              <b-col md="4">
                <Button
                  :disabled="$v.form.$invalid"
                  class="botton py-2h-51 button--filled button--subscribe"
                  :text="$t('button.subscribe')"
                  :loading="loading"
                  @click="save"
                ></Button>
              </b-col>
            </b-row>
          </form-wrapper>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
2
<script>
import { mapGetters } from "vuex";
import { StoreData } from "@/helpers/apiMethods";
import { email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //   subscribe: "",
      form: {
        email: ""
      },
      subscribeDescription: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.subscribeDescription = this.settings(
          "global_subscription_description"
        );
      },
      immediate: true
    }
  },
  methods: {
    save() {
      this.loading = true;

      // Subscribe
      StoreData({
        reqName: "subscriptions",
        data: { ...this.form }
      })
        .then(() => {
          this.reset();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      this.form.email = "";
      this.$v.$reset();
    }
  },
  validations() {
    return {
      form: {
        email: {
          email
        }
      }
    };
  }
};
</script>
<style lang="scss">
.h-30 {
  max-height: 30vh;
}
.subscribe {
  margin-bottom: 5rem !important;
}
</style>
